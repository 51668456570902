.place-order-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}


.breadcrumbs {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically centered */
  position: relative; /* Ensure parent is positioned for absolute children */
}

.breadcrumbs span {
  margin: 0 10px;
  font-weight: bold;
}

.breadcrumbs span.active {
  color: orange; /* Your theme color */
}

.form-stage {
  margin-bottom: 30px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-input:focus {
  border-color: orange; /* Your theme color */
  outline: none;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-next,
.btn-back,
.btn-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: orange; /* Your theme color */
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-next:hover,
.btn-back:hover,
.btn-submit:hover {
  background-color: #e67e22; /* Darker shade of orange */
}

.order-summary {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.order-summary table {
  width: 100%;
  border-collapse: collapse;
}

.order-summary th,
.order-summary td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.order-summary th {
  background-color: #f9f9f9;
}

.payment-options {
  margin-top: 20px;
}

.payment-options label {
  display: block;
  margin: 10px 0;
}

.payment-form {
  margin-top: 20px;
}

.payment-form input {
  margin: 10px 0;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .place-order-container {
    padding: 15px;
  }

  .btn-next,
  .btn-back,
  .btn-submit {
    width: 100%;
    margin: 5px 0;
  }

  .button-group {
    flex-direction: column;
  }

  .order-summary {
    margin-top: 10px;
  }
}
/* Main container */
.place-order-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Postcard container structure */
.postcard-container {
  background: #fff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.totals {
  margin-top: 20px;
}

/* Arrow shape beside h3 */
.arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e76f51; /* Arrow color */
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* Header h3 styling */
.postcard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Bottom part of the postcard (body) */
.postcard-body {
  background-color: #fff;
  padding: 20px;
  color: #333;
}

/* Style inputs within the postcard */
.postcard-body input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button group */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-next, .btn-back {
  background: #ffa500;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.btn-next:hover, .btn-back:hover {
  background: #e59400;
}


/* CSS/PlaceOrder.css */

/* Breadcrumbs Container */
.breadcrumbs-container {
  background-color: #ffa500; /* Orange background */
  padding: 10px 20px; /* Add padding for aesthetics */
  border-radius: 10px; /* Rounded corners */
}

/* Breadcrumbs Title */
.breadcrumbs-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
  color: #333; /* Heading color */
}

/* Breadcrumb Item */
.breadcrumb-item {
  position: relative;
  color: white; /* Default text color */
  margin-right: 20px; /* Space between items */
  padding: 10px 15px; /* Padding for items */
  transition: color 0.3s; /* Smooth color transition */
}

.breadcrumb-item.active {
  color: #c66f0a; /* Dark orange for active item */
}

.number {
  display: inline-block;
  width: 30px; /* Circle width */
  height: 30px; /* Circle height */
  border-radius: 50%; /* Make it a circle */
  background-color: #444; /* Dark background for inactive */
  color: white; /* White text */
  text-align: center; /* Center text */
  line-height: 30px; /* Center text vertically */
  margin-right: 8px; /* Space between number and text */
  transition: background-color 0.3s; /* Smooth background transition */
}

.number.active {
  background-color: #ffeb8a; /* Light orange for active number */
}

/* Arrow Indicator */
.arrow {
  position: absolute;
  right: -20px; /* Positioning to the right of the item */
  top: 0; /* Align top */
  height: 100%; /* Full height of the breadcrumb item */
  width: 20px; /* Width of the arrow */
  border-left: solid 20px transparent; /* Transparent left border */
  border-right: solid 20px #ffa500; /* Orange right border for non-active */
  border-top: solid 20px transparent; /* To create a triangular shape */
  border-bottom: solid 20px transparent; /* To create a triangular shape */
  transition: border-color 0.3s; /* Smooth color transition */
}

/* Adjust arrow color for active breadcrumb */
.breadcrumb-item.active .arrow {
  border-left-color: #c66f0a; /* Dark orange arrow for active item */
}


.breadcrumb-item .arrow {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  border: solid transparent;
  border-width: 10px;
  border-left-color: #f8f8f8;
  content: '';
}