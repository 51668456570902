.orders-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .orders-list {
    margin-top: 20px;
  }
  
  .order-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin: 15px 0;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
  }
  
  .order-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .order-items {
    margin-top: 10px;
  }
  
  .order-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .order-item img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .item-details {
    flex-grow: 1;
  }
  
  .status {
    font-weight: bold;
  }
  
  .status.pending {
    color: orange;
  }
  
  .status.completed {
    color: green;
  }
  
  .status.cancelled {
    color: red;
  }
  
  .view-receipt {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-receipt:hover {
    background-color: #0056b3;
  }
  