/* General layout */
.notification-page {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9; 
    font-family: Arial, sans-serif;
}

/* Side menu */
.side-menu {
    width: 220px; /* Slightly reduced width */
    background-color: white;
    color: white;
    padding: 15px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.side-menu ul {
    list-style-type: none;
    padding: 0;
}

.side-menu ul li {
    padding: 12px 0; /* Adjust padding */
    border-bottom: 1px solid #444;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.side-menu ul li:hover {
    background-color: #555;
}

/* Main content */
.notification-content {
    flex: 3; /* Takes 3 parts of the available space */
    padding: 25px; /* Added more padding */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    margin-left: 10px; /* Slight gap from the side menu */
}

.notification-header {
    text-align: center;
    font-family: 'Playfair Display', serif; /* Use a stylish font like Playfair Display */
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;
}

/* Filters section (search bar) */
.notification-filters {
    display: flex;
    justify-content: flex-end;  /* Center horizontally */
    align-items: center; /* Center vertically if needed */
    margin-bottom: 20px; /* Optional: Add space below the filters */
}

.search-input {
    flex: 1; /* Take more space */
    max-width: 400px; /* Limit width */
    padding: 8px;
    border-radius: 5px;
    margin-right: 15px; /* Space between search and filter */
   
}

.show-read-toggle {
    display: flex;
    align-items: center; /* Vertically centers items */
    margin-bottom: 15px; /* Optional: Add margin for spacing */
  }

.show-read-toggle input[type='checkbox'] {
    margin-right: 10px;
}
/* Select All Checkbox */
.select-all {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space below the select all */
}

.select-all input[type='checkbox'] {
    margin-right: 10px; /* Space between checkbox and label */
}

select {
    padding: 5px;
    font-size: 1rem;
  }
/* Mark Options Buttons */
.mark-options {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the label and the select dropdown */
    margin-bottom: 15px; /* Space below the row */
}
.mark-options label {
    font-weight: bold; /* Space between label and dropdown */
}

.mark-options button {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}

.mark-options button:hover {
    background-color: darkorange;
}

.mark-options select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer; /* Pointer cursor on hover */
    appearance: none; /* Remove default arrow */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="black"><path d="M10 13l5-5H5z"/></svg>') no-repeat right 10px center; /* Custom arrow */
    background-size: 10px; /* Arrow size */
}

.mark-read-button {
    padding: 8px 15px;
    background-color: orange;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 20px;
}

/* Container for both select action and show read button */
.notification-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Add some space below */
    width: 100%; /* Ensures the container takes up full width */
}


.notifications-per-page {
    display: flex;
    align-items: center;
    gap: 10px; /* Adds space between the icon and the dropdown */
  }

.per-page-select {
    margin-left: 10px;
}

.filter-icon {
    cursor: pointer; /* Makes the icon look clickable */
  }

/* Notification List */
.notification-list-container {
    margin-top: 15px;
}


/* Aligning Sort and Mark Options */
.sort-and-mark-options {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
    width: 60%;
}

.notification-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align message and date */
    padding: 10px 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
}

.notification-item:hover {
    background-color: #f2f2f2; /* Subtle hover effect */
}

.notification-item.read {
    background-color: #f0f0f0;
}

.notification-item.unread {
    background-color: #fff4f4;
    font-weight: bold;
}

.notification-snippet {
    flex: 1; /* Ensure it takes available space */
    margin-right: 15px;
    cursor: pointer;
}

.notification-date {
    white-space: nowrap; /* Prevent date from wrapping */
}

/* No Notifications Message */
.no-notifications {
    text-align: center;
    margin-top: 20px; /* Space above message */
}

/* Notification Detail */
.notification-detail {
    margin-top: 20px; /* Space above detail view */
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-item {
    padding: 8px 12px;
    margin: 0 5px;
    background-color: #f5f5f5;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-item:hover, .pagination-item.active {
    background-color: orange;
    color: white;
}

.pagination-item.active {
    background-color: orange;
    color: white;
}


/* Sort by Latest/Oldest */
.sort-dropdown {
    display: inline-block;
}

.sort-dropdown select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.sort-by {
    display: flex;
    align-items: center;
    
}

.sort-by label {
    margin-right: 10px; /* Space between label and dropdown */
}

.sort-by select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Message Management Group */
.message-management {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.message-management button {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.message-management button:hover {
    background-color: darkorange;
}

.close-detail-button {
    background-color: orange;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px;
}

.close-detail-button:hover {
    background-color: darkorange;
}

/* Back Arrow Icon (to go back to the inbox) */
.back-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: orange;
    font-size: 16px;
}

.back-arrow svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.back-arrow:hover {
    color: darkorange;
}