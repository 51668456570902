
.two-fa-settings {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .toggle-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .toggle-btn.enabled {
    background-color: #dc3545; /* Red for disable */
    color: white;
  }
  
  .toggle-btn.disabled {
    background-color: #28a745; /* Green for enable */
    color: white;
  }
  
  .confirmation-modal {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  
  .qr-code-section {
    margin-top: 20px;
  }
  
  .qr-code-section img {
    max-width: 100%;
    height: auto;
  }
  
  /* TwoFactorVerification.css */
  .two-fa-verification {
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .verification-input {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .verify-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    cursor: pointer;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  