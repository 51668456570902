/* src/Pages/CSS/DataSubscription.css */

.data-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.data-image {
  max-width: 80%;
  height: auto;
}

.data-subscription {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.data-header {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.service-toggle {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.toggle-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease;
}

.toggle-button.active,
.toggle-button:hover {
  background-color: #ff6600;
  color: #fff;
}

.data-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.form-group select,
.form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.subscribe-button {
  padding: 12px 20px;
  font-size: 18px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #cc5200;
}
