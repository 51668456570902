.productdisplay{
    display: flex;
    padding: 0px 10%;
    gap: 40px;
    justify-content: space-between;
}
.productdisplay-left{
    display: flex;
    gap: 18px;
}
.productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.productdisplay-img-list img{
    height: 110px;
    max-width: 120px;
}
.productdisplay-main-img{
    height: 500px;
    /* max-width: 420px; */
}
.productdisplay-right{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.productdisplay-right h1{
    color: #3d3d3d;
    font-size: 30px;
    font-weight: 700;
}
.productdisplay-right-stars{
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 14px;
}
.productdisplay-right-prices{
    display: flex;
    margin: 25px 0px;
    gap: 20px;
    font-size: 22px;
    font-weight: 700;
}
.productdisplay-right-price-old{
    color: #818181;
    text-decoration: line-through;
}
.productdisplay-right-price-new{
    color: #ff4141;
}
.productdisplay-right-size h1{
    margin-top: 30px;
    color: #656565;
    font-size: 18px;
    font-weight: 600;
}
.productdisplay-right-sizes{
    display: flex;
    margin: 20px 0px;
    gap: 15px;
}
.productdisplay-right-sizes div{
    padding: 16px 20px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}
.productdisplay-right button{
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #FF4141;
    margin-bottom: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}
.productdisplay-right-category{
    margin-top: 10px;
}
.productdisplay-right-category span{
    font-weight: 600;
}

/* ----- media query------- */


@media(max-width:1024px){
    .productdisplay{
        padding: 0px 8%;
    }
    .productdisplay-img-list{
        gap: 10px;
    }
    .productdisplay-img-list img{
        height: 90px;
    }
    .productdisplay-main-img{
        height: 400px;
    }
    
}
@media(max-width:900px){
    .productdisplay{
        gap: 20px;
    }
    .productdisplay-left{
        gap: 10px;
    }
    .productdisplay-img-list{
        gap: 10px;
    }
    .productdisplay-img-list img{
        height: 80px;
    }
    .productdisplay-main-img{
        height: 350px;
    }
    
}
@media(max-width:800px){
    .productdisplay{
        flex-wrap: wrap;
    }
    .productdisplay-left{
        width: 100%;
        justify-content: center;
    }
}
@media(max-width:500px){
    .productdisplay-right h1 {
        font-size: 20px;
    }
    .productdisplay-right-prices {
        margin: 15px 0;
        font-size: 18px;
    }
    .productdisplay-right-size h1{
        margin-top: 15px;
    }
    .productdisplay-right-size h1{
        margin: 10px 0;
    }
    .productdisplay-right-size div{
        font-size: 14px;
    }
    .productdisplay-img-list img{
        height:68px;
    }
    .productdisplay-main-img{
        height: 300px;
    }
}