/* TransferForm.css */
form {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9; /* Light gray background */
}

form h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #333;
}

form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

form button {
    padding: 10px;
    background-color: #ff6f00; /* Orange background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

form button:hover {
    background-color: #e65c00; /* Darker orange */
}
