/* WishlistButton.css */
.wishlist-button {
    background-color: #ff7f50; /* Classy coral color */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 20px; /* Rounded corners */
    padding: 5px 10px; /* Smaller padding for a smaller button */
    font-size: 14px; /* Smaller font size */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.wishlist-button:hover {
    background-color: #ff6347; /* Darker coral on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.wishlist-button:focus {
    outline: none; /* Remove outline */
    box-shadow: 0 0 0 3px rgba(255, 127, 80, 0.5); /* Custom outline */
}
