.order-complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #f6f9fc;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #525f7f;
  }
  
  .order-complete h2 {
    color: #32325d;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .order-complete p {
    font-size: 1rem;
    margin: 8px 0;
    color: #6b7c93;
  }
  
  .order-complete button {
    background-color: orange;
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .order-complete button:hover {
    background-color: #5469d4;
  }
  
  .order-complete button:active {
    transform: scale(0.98);
  }
  
  .order-complete .order-details {
    max-width: 400px; /* Moderate size */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    text-align: center;
    margin: 20px; /* Add margin for spacing */
  }
  
  .loading-text {
    font-size: 1.2rem;
    color: #a0aec0;
  }
  
  /* Optional: Responsive adjustments */
  @media (max-width: 600px) {
    .order-complete .order-details {
      width: 90%; /* Full width on smaller screens */
      margin: 0; /* Remove margin on smaller screens */
    }
  }
  