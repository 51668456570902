.airtime-recharge {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  
  .airtime-recharge h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .airtime-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  
  }
  
  .airtime-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  .airtime-header {
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
  }

  /* Banner Styling */
  .banner-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  form {
    max-width: 500px;
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  button {
    width: 100%;
    padding: 15px;
    background-color: #ff8800; /* Updated button color to orange */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #e06f00; /* Darker shade for hover */
  }
  
  .airtime-container {
    display: flex;
    justify-content: center;
  }

  .payment-confirmation {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
}

.payment-confirmation h2 {
    margin-bottom: 10px;
}

.payment-confirmation table {
    width: 100%;
    margin-bottom: 10px;
}

.payment-confirmation button {
    margin-top: 10px;
}