/* stripeCheckout.css */

/* Body and Container Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa; /* Light background for contrast */
    margin: 0;
    padding: 0;
}

#checkout {
    max-width: 600px; /* Set a max width for the checkout */
    margin: 2rem auto; /* Center the checkout section */
    padding: 2rem;
    background-color: #ffffff; /* White background for the checkout section */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Header Styles */
h2 {
    text-align: center; /* Center the header */
    color: #333; /* Dark text color */
    margin-bottom: 1.5rem; /* Space below the header */
}

/* Button Styles */
button {
    background-color: #4CAF50; /* Green button */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    padding: 12px 20px; /* Padding */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    width: 100%; /* Full-width button */
    transition: background-color 0.3s ease; /* Smooth background change */
}

button:hover {
    background-color: #45a049; /* Darker green on hover */
}

/* Payment Message Styles */
#payment-message {
    color: red; /* Error message in red */
    text-align: center; /* Center the message */
    margin-top: 1rem; /* Space above */
}

/* Dynamic Payment Methods Annotation Styles */
#dpm-annotation {
    margin-top: 1.5rem; /* Space above */
    text-align: center; /* Center the annotation text */
    font-size: 14px; /* Smaller font size */
}

#dpm-integration-checker {
    color: #007BFF; /* Link color */
    text-decoration: none; /* No underline */
}

#dpm-integration-checker:hover {
    text-decoration: underline; /* Underline on hover */
}
