/* CartItems.css */

/* Container Styling */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Product Details */
.product-details {
  padding: 20px;
}

.product-details h6 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.product-details .card {
  background-color: #f9f9f9;
  border: none;
  border-radius: 8px;
}

/* Table Styles */
.table-shopping-cart {
  width: 100%;
}

.table-shopping-cart thead {
  background-color: #f1f1f1;
  color: #555;
  font-weight: bold;
}

.table-shopping-cart th,
.table-shopping-cart td {
  padding: 15px;
  vertical-align: middle;
  text-align: left;
}

.table-shopping-cart img.img-sm {
  max-width: 80px;
  border-radius: 8px;
}

/* Quantity Selector */
.form-control {
  width: 80px;
}

/* Action Buttons */
.wishlist-btn,
.remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.wishlist-btn .text-warning {
  color: green; /* Set a visible color for the heart icon (Orange) */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.wishlist-btn:hover .text-warning {
  color: #FF4500; /* Darker orange or red color on hover */
}

.remove-btn .text-danger {
  color: #FF0000; /* Set a visible color for the trash icon (Red) */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.remove-btn:hover .text-danger {
  color: #B22222; /* Darker red color on hover */
}



.summary-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
}






/* Button Group */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-group .btn {
  flex: 1;
  margin-right: 10px;
  padding: 15px;
  font-size: 1.2rem;
  border-radius: 5px;
}

.button-group .btn-primary {
  background-color: #ff6600;
  color: #fff;
  border: none;
}

.button-group .btn-secondary {
  background-color: #ccc;
  color: #333;
}

.button-group .btn:hover {
  opacity: 0.9;
}



/* CartItems.css */
.d-flex {
  justify-content: space-between; /* Space between elements in the flex row */
}

.container.cart {
  max-width: 90%; 
  margin: auto; /* Keep the main cart container a bit smaller */
}


.back-arrow {
  margin-right: 5px; /* Space between the arrow and text */
  color: #333; /* Color of the arrow */
  font-size: 1.2rem; /* Size of the arrow */
}

.continue-shopping {
  color: #333; /* Link color */
  font-weight: 600; /* Link text weight */
  font-size: 1rem; /* Font size for better visibility */
}

.continue-shopping:hover {
  color: orange; /* Link hover color */
}

.summary {
  width: 100%; /* For smaller screen sizes */
  max-width: 300px; /* Reduce width on larger screens */
  margin-left: auto;
  padding: 15px; /* Added padding for spacing */
}

.summary-card {
  background: #f8f9fa; /* Light background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 1.2rem; /* Smaller title size */
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.summary-list dt,
.summary-list dd {
  font-size: 0.9rem; /* Reduced font size for items */
  color: #666;
}

.summary-list dt {
  font-weight: 500;
}

.summary-list dd {
  font-weight: 600;
}

.button-group .checkout {
  display: block;
  width: 100%;
  font-size: 0.9rem; /* Reduced font size for button */
  padding: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.button-group .checkout:hover {
  background-color: darkorange; /* Darker shade on hover */
}



