/* General Footer Styling */
.footer {
    padding: 20px;
    background-color: #f8f9fa;
    color: #383838;
    font-family: Arial, sans-serif;
  }
  
  /* Footer Container */
  .footer-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Footer Logo */
  .footer-logo {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .footer-logo p {
    font-size: 30px;
    font-weight: 700;
  }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    justify-content: flex-start; /* Move links to the left */
    gap: 20px;
    list-style: none;
    padding: 0;
    font-size: 0.875rem; /* Reduced font size */
  }
  
  .footer-links li {
    cursor: pointer;
    color: #252525;
    transition: color 0.3s ease;
  }
  
  .footer-links li:hover {
    color: #f97316; /* Orange theme */
  }
  
  /* Footer Get in Touch */
  .footer-get-in-touch {
    text-align: left;
    color: black; /* Set text to black */
    margin-bottom: 20px; /* Add spacing between Get in Touch and social icons */
  }
  
  .footer-get-in-touch ul {
    list-style: none; /* Remove list bullets */
    padding: 0;
  }
  
  .footer-get-in-touch li {
    font-size: 0.875rem; /* Reduced font size for Get In Touch */
  }
  
  /* Social Icons Section */
  .footer-social-icons {
    display: flex;
    justify-content: flex-start; /* Align icons to the left */
    gap: 20px;
    margin-left: 10px;
  }
  
  /* Remove box shadow and styling from social icons */
  .social-icon {
    width: 25px;
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .social-icon:hover {
    filter: brightness(0) saturate(100%) invert(58%) sepia(81%) saturate(6183%) hue-rotate(344deg) brightness(93%) contrast(103%); /* Orange hover effect */
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  /* Footer Copyright */
  .footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    font-size: 14px;
  }
  
  .footer-copyright hr {
    width: 80%;
    border: none;
    height: 1px;
    background-color: #c7c7c7;
  }
  
  @media (min-width: 768px) {
    .footer-container {
      grid-template-columns: 1fr 1fr 1fr; /* Three columns for larger screens */
      gap: 30px;
    }
  
    .footer-get-in-touch,
    .footer-social-icons {
      grid-column: span 1;
    }
  }
  

/* Footer.css */

/* Dark Mode Toggle Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

/* Dark Mode Body */
.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

/* Dark Mode Specific Elements (optional) */
.dark-mode .footer {
  background-color: #1e1e1e;
}
