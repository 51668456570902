/* Container Styling */
.success-container {
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f7f9fc; /* Light background */
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    font-family: 'Poppins', sans-serif; /* Modern, chic font */
    text-align: center;
    color: #333;
}

/* Main Heading */
h2 {
    font-size: 26px;
    color: #ff7f50; /* Classy coral color for attention */
    margin-bottom: 30px;
    font-weight: 600;
}

/* Invoice Section Styling */
.invoice-section {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05); /* Softer shadow for the section */
    margin-bottom: 20px;
    text-align: left;
}

/* Section Heading */
.invoice-section h3 {
    font-size: 20px;
    color: #444;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    font-weight: 500;
}

/* Text Styles */
.invoice-section p {
    font-size: 16px;
    color: #555; /* Subtle, readable text */
    margin: 10px 0;
    line-height: 1.6;
}

.invoice-section strong {
    color: #333;
    font-weight: 600;
}

/* Button Styling */
button {
    display: inline-block;
    width: 100%;
    padding: 12px 0;
    background-color: #ff7f50; /* Stylish coral color */
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #ff4500; /* Slightly darker hover color */
}

/* Footer Text */
.footer-text {
    font-size: 12px;
    color: #888;
    margin-top: 20px;
}

/* Link Styles */
a {
    color: #ff7f50;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .success-container {
        margin: 20px 10px;
        padding: 15px;
    }

    h2 {
        font-size: 22px;
    }

    .invoice-section {
        padding: 15px;
    }

    .invoice-section h3 {
        font-size: 18px;
    }

    .invoice-section p {
        font-size: 14px;
    }

    button {
        font-size: 14px;
        padding: 10px 0;
    }
}
