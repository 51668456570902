/* Add this CSS to your styles */
.review {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
  }
  
  .review img {
    max-width: 100px;
    margin-top: 10px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  form input,
  form select,
  form textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  form button {
    background-color: #ff5722; /* Your theme color */
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  form button:hover {
    background-color: #e64a19; /* Darker shade for hover effect */
  }

  .star {
    font-size: 24px; /* Adjust the size as needed */
    color: lightgray; /* Default color for empty stars */
  }
  
  .star.filled {
    color: gold; /* Color for filled stars */
  }
  