.wallet {
  padding: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  color: #f8f9fa;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.wallet-section {
  margin-bottom: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.wallet-section:hover {
  transform: translateY(-5px);
}

.transaction-card {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.transaction-card:hover {
  background: rgba(255, 255, 255, 0.4);
}

.chart-section {
  height: 400px; /* Adjust height as needed */
  background: rgba(255, 255, 255, 0.15);
  padding: 20px;
  border-radius: 8px;
}

.secure-badge {
  display: flex;
  align-items: center;
  background-color: rgba(0, 128, 0, 0.4);
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 128, 0, 0.2);
}

.secure-badge img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.create-wallet-icon {
  font-size: 1.8em;
  color: #ff8c00;
  cursor: pointer;
  transition: color 0.3s ease;
}

.create-wallet-icon:hover {
  color: #ffa500;
}

.wallet h2 {
  color: #e6e6e6;
  font-weight: bold;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  outline: none;
}
