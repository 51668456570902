
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Set base font-size for mobile first */
html {
    font-size: 16px; /* Base font size for mobile */
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    padding: 0;
    margin: 0;
}

/* Make images responsive */
img {
    max-width: 100%;
    height: auto;
}

/* Flexbox layout example for responsiveness */
.container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        padding: 20px;
    }
}

@media (min-width: 1024px) {
    .container {
        padding: 40px;
    }
}

/* Typography using relative units */
h1 {
    font-size: 2.5rem;
}

p {
    font-size: 1rem;
}

/* Responsive Grid Example */
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1024px) {
    .grid-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* Responsive navigation */
.navbar {
    display: flex;
    flex-direction: column;
    background-color: #ff6600;
    padding: 10px;
}

@media (min-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
    }
}

.navbar a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
}

/* Button styles */
button {
    background-color: #ff6600;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #ff5500;
}

/* Media queries for layout adjustments */
@media (min-width: 768px) {
    body {
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    body {
        font-size: 20px;
    }
}


@tailwind base;
@tailwind components;
@tailwind utilities;


/* In your global CSS file */
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css"; 
