.new-collections{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
   margin-bottom: 100px;
}
.new-collections h1{
    color: #171717;
    font-size: 40px;
    font-weight: 600;
}
.new-collections hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
  }
.collections{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 60px auto;
    gap: 30px;
    max-width: 1280px;
}
