.create-wallet-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
}

h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.create-wallet-form .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
}

label {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
}

input[type="email"],
input[type="text"] {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border 0.3s ease;
}

input[type="email"]:focus,
input[type="text"]:focus {
    border-color: #ff7f50;
    outline: none;
}

.create-button {
    display: inline-block;
    width: 100%;
    padding: 0.75rem;
    background-color: #ff7f50;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #e76a35;
}

.wallet-info {
    padding: 1.5rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 1.5rem;
}

.success-message {
    color: #28a745;
    font-weight: bold;
    margin-bottom: 1rem;
}

.error-message {
    color: #d9534f; /* Bootstrap danger color */
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px; /* Adjust font size as needed */
}

.back-button {
    margin-top: 1.5rem;
    padding: 0.5rem 1.5rem;
    background-color: #ff7f50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #e76a35;
}

.signup-link {
    color: #007bff; /* Bootstrap primary color */
    text-decoration: none; /* Remove underline */
    margin-left: 5px; /* Space between message and link */
    font-weight: normal; /* Normal font weight */
}

.signup-link:hover {
    text-decoration: underline; /* Add underline on hover */
    color: #0056b3; /* Darken the color on hover */
}
