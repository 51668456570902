.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px -2px black;
    padding: 15px 70px;
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-logo img {
    width: 45px;
}

.nav-logo p {
    color: #171717;
    font-size: 28px;
    font-weight: 600;
}

.nav-icons {
    display: flex;
    align-items: center;
    position: relative;  /* Adjust the gap between icons as needed */
    justify-content: space-between; 
}

.nav-icons .icon {
    margin-left: 15px; /* Consistent margin between all icons */
    width: 20px;
    height: 20px;
}

.nav-icons .login-icon {
    margin-right: 15px; /* Add margin-right to the login icon to prevent it from being too close to the cart */
}

.icon {
    margin-left: 15px;
    width: 20px; /* Adjust this value for your icon size */
    height: 20px; /* Adjust this value for your icon size */
    transition: transform 0.2s ease; /* Animation for hover */
}

.search-icon-wrapper {
    margin-right: 10px; /* Reduce space between search icon and login icon */
  }


  .login-icon {
    margin-left: 10px; /* Adjust space between login and cart icons */
  }
/* Remove background shadow for login icon */
.icon.no-shadow {
    box-shadow: none;
  }

.icon:hover {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
    filter: brightness(1.2); /* Brighten the icon on hover */
}


/* Cart badge (remains unchanged) */
.cart-count {
    position: absolute;
    top: 10px;
    right: 60px;   
    color: black;
    padding: 2px 6px;
    align-items: center;
    border-radius: 70%;
    font-size: 12px;
    background-color: orange;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px; /* Adjust position based on your layout */
    right: 0; /* Align to the right side of the profile icon */
    background-color: white; /* Background of the dropdown */
    border: 1px solid #ccc; /* Optional border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for dropdown */
    padding: 10px 0; /* Padding around the dropdown */
    z-index: 10; /* Ensure dropdown is on top */
    min-width: 150px; /* Set minimum width */
    border-radius: 4px; /* Rounded corners */
}

.dropdown-item {
    padding: 10px 20px; /* Padding for dropdown items */
    color: #171717; /* Text color */
    text-decoration: none; /* Remove underline */
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: orange; /* Background color on hover */
    color: white; /* Text color on hover */
}


.notification-list {
    position: absolute;
    background: white; /* or your desired background */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto; /* Scroll if there are many notifications */
    width: 200px; /* Adjust as needed */
  }
  
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Separator */
  }
  
  .notification-item:last-child {
    border-bottom: none; /* Remove last separator */
  }

  
  .notification-dropdown {
    position: absolute;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px; /* Adjust width as necessary */
    border-radius: 4px;
}

.notification-bell {
    position: relative;
  }

.notification-bell-icon {
    color: orange; /* Change to orange */
}

/* Hover effect for the notification bell */
.notification-bell-icon:hover {
    color: darkorange; /* Darker shade on hover */
}

.sort-by {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px solid #ddd;
}
.sort-by label {
    margin-right: 10px;
}

.notification-item {
    padding: 8px;
    cursor: pointer;
}

.notification-item:hover {
    background-color: #f1f1f1; 
}

.notification-count {
    position: absolute;
    bottom: -5px; /* Adjust to position it below the cart icon */
    right: -10px; /* Adjust to position it to the right of the cart icon */
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: indianred;
    color: white;
    font-size: 12px;
}



/* Cart badge */
.nav-cart-count {
    position: absolute;
    bottom: -5px; /* Adjust to position it below the cart icon */
    right: -10px; /* Adjust to position it to the right of the cart icon */
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: orange;
    color: white;
    font-size: 12px;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 40px;
    color: #626262;
    font-size: 16px;
    font-weight: 500;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}

.nav-menu hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}
.nav-dropdown {
    display: none;
    cursor: pointer;
  }



/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .nav {
        padding: 10px 20px; /* Reduce padding for smaller screens */
    }

    .nav-logo img {
        width: 35px; /* Adjust logo size for smaller screens */
    }

    .nav-logo p {
        font-size: 20px; /* Reduce font size for logo text */
    }

    .nav-icons {
        gap: 10px; /* Reduce the gap between icons */
        justify-content: flex-end; /* Aligns icons properly on smaller screens */
    }

    .icon {
        width: 18px; /* Adjust icon size for smaller screens */
        height: 18px;
    }

    .cart-count {
        top: 5px; /* Adjust cart badge positioning */
        right: 10px;
    }

    .nav-menu {
        display: none;
        flex-direction: column; /* Stack menu items vertically */
        position: absolute;
        top: 60px; /* Adjust the top position to show below navbar */
        right: 0;
        background-color: white;
        width: 100%; /* Ensure the menu takes full width on small screens */
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for dropdown */
        gap: 15px; /* Reduce the gap between menu items */
    }

    .nav-menu-visible {
        display: flex;
    }

    /* Show dropdown icon for small screens */
    .nav-dropdown {
        display: block;
        width: 25px;
        height: 25px;
    }
}

/* Other existing styles remain unchanged */


