/* General container styling */
.wishlist-page {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Side menu styling */
  .side-menu {
    width: 20%;
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .side-menu ul {
    list-style: none;
    padding: 0;
  }
  
  .side-menu ul li {
    padding: 10px 0;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .side-menu ul li.active,
  .side-menu ul li:hover {
    color: #ff6f61;
  }
  
  /* Wishlist container styling */
  .wishlist-container {
    flex: 1;
    margin-left: 20px;
  }
  
  .wishlist-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Wishlist items */
  .wishlist-items {
    list-style: none;
    padding: 0;
  }
  
  .wishlist-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .wishlist-item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 15px;
  }
  
  /* Item details */
  .wishlist-item-details {
    flex: 1;
  }
  
  .wishlist-item-name {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .wishlist-item-price {
    font-size: 16px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .wishlist-item-discount {
    color: #ff6f61;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .wishlist-item-stock {
    font-size: 14px;
    color: #ff6f61;
  }
  
  /* Action buttons */
  .wishlist-item-actions {
    display: flex;
    flex-direction: column;
  }
  
  .buy-now-btn {
    background-color: #ff6f61;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
  }
  
  .buy-now-btn:hover {
    background-color: #ff3e29;
  }
  
  .remove-btn {
    background-color: #fff;
    color: #ff6f61;
    padding: 10px 20px;
    border: 1px solid #ff6f61;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .remove-btn:hover {
    background-color: #ff6f61;
    color: #fff;
  }
  
  /* Pagination styling */
  .pagination {
    text-align: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
    padding: 8px 16px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination button.active {
    background-color: #ff6f61;
    color: white;
    border: none;
  }
  
  .pagination button:hover {
    background-color: #ff6f61;
    color: white;
  }
  