.two-fa-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa; /* Light gray background */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .two-fa-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .two-fa-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .two-fa-button {
    background-color: #ff7f00; /* Orange button */
    color: #fff;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .two-fa-button:hover {
    background-color: #e66a00; /* Darker orange on hover */
  }
  
  .two-fa-toggle {
    margin-top: 15px;
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
  }
  
  .two-fa-toggle:hover {
    text-decoration: underline;
  }
  
  .two-fa-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .two-fa-settings {
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .two-fa-container {
      padding: 10px;
    }
  }
  