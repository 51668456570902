* {
  margin: 0;
  padding: 0;
  font-family: 'poppins';
}

a {
  text-decoration: none !important;
  color: inherit;
}

