

.cod-checkout-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 40px;
    background-color: #fdfdfd;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    font-family: 'Lato', sans-serif; /* Modern and chic font */
}

.cod-checkout-container h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 2px solid #ff6600; /* Adds an elegant underline */
    display: inline-block;
    padding-bottom: 10px;
}

.cod-checkout-container p {
    font-size: 18px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.6;
}

.cod-checkout-container .order-details {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    border-left: 5px solid #ff6600; /* Highlights the details */
}

.cod-checkout-container .order-details p {
    margin: 8px 0;
    font-size: 16px;
    color: #555;
    font-weight: 500;
}

.cod-checkout-container .order-details p span {
    font-weight: 700;
    color: #333;
}

.cod-checkout-container button {
    display: block;
    width: 100%;
    padding: 14px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background-color: #ff6600;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-shadow: 0 8px 15px rgba(255, 102, 0, 0.3); /* Soft shadow for button */
}

.cod-checkout-container button:hover {
    background-color: #e65c00;
    box-shadow: 0 8px 20px rgba(255, 102, 0, 0.4);
}

.cod-checkout-container .order-summary {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.cod-checkout-container .summary-details {
    font-size: 18px;
    color: #444;
    margin-bottom: 20px;
}

.cod-checkout-container .summary-details span {
    font-weight: 600;
    color: #000;
}

.cod-checkout-container .footer-text {
    text-align: center;
    color: #777;
    font-size: 14px;
    margin-top: 30px;
    line-height: 1.6;
    font-style: italic;
}

.cod-checkout-container .footer-text a {
    color: #ff6600;
    text-decoration: none;
    font-weight: 600;
}

.cod-checkout-container .footer-text a:hover {
    text-decoration: underline;
}
