.utility-services {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Banner Styling */
.banner-container {
  width: 100%;
  margin-bottom: 30px;
}

.banner-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Header Styling */
.utility-header {
  font-size: 32px;
  margin-bottom: 40px;
  color: #333;
  font-weight: 600;
}

/* Services Section */
.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px; /* Space between service items */
  margin-top: 20px;
}

.service-item {
  flex: 1 1 200px; /* Make items flexible with a minimum size */
  max-width: 220px;
  margin: 0 auto;
}

/* Updated Button Styling to Match Orange Theme */
.service-button {
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  background-color: #ff8800; /* Orange color for buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.service-button:hover {
  background-color: #e06f00; /* Darker orange for hover */
}

/* Global Spacing and Styling */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #eef2f7;
}

